body {
  margin: 0;
}
#root {
  min-height: 100vh;
  font-size: 16px;
  background-color: #F1EDE4;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Semi Bold';
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}