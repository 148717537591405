@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-correct-answer-feedback {
  background: rgb(221, 236, 255);
  background: -moz-radial-gradient(circle,
      rgba(221, 236, 255, 1) 0%,
      rgba(210, 230, 255, 1) 77%,
      rgba(191, 219, 254, 1) 100%);
  background: -webkit-radial-gradient(circle,
      rgba(221, 236, 255, 1) 0%,
      rgba(210, 230, 255, 1) 77%,
      rgba(191, 219, 254, 1) 100%);
  background: radial-gradient(circle,
      rgba(221, 236, 255, 1) 0%,
      rgba(210, 230, 255, 1) 77%,
      rgba(191, 219, 254, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ddecff", endColorstr="#bfdbfe", GradientType=1);
}


.highlighter {
  width: 100%;
  color: #95f7b2e8;
  transition: width 1000ms ease-out, color 1000ms ease-out;
  animation: pulsate 2s;
  /* 2s duration, infinite loop */
}

.pulsating {
  animation: pulsate 4s;
}

.activated {
  animation: pulsate 2s;
  /* background pulses red */
  /* background-color: #f79d81; */
  /* text pulses white */
  /* color: #000000; */
}